<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9 6.69317C12.4467 4.39317 10.4267 2.6665 8 2.6665C6.07333 2.6665 4.4 3.75984 3.56667 5.35984C1.56 5.57317 0 7.27317 0 9.33317C0 11.5398 1.79333 13.3332 4 13.3332H12.6667C14.5067 13.3332 16 11.8398 16 9.99984C16 8.23984 14.6333 6.81317 12.9 6.69317ZM9.33333 8.6665V11.3332H6.66667V8.6665H4.66667L8 5.33317L11.3333 8.6665H9.33333Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>